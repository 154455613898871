	var controller = new slidebars();
	controller.init();


	currentID = '';
	mainID = '';
	function toggleNavbar() {
		//controller.toggle( 'id-'+id );
		if (mainID == '') {
			mainID = document.getElementById('mobileNavbarData').dataset.mainid;
		}
		toggleSection(mainID)
		currentID = mainID;
		
		controller.toggle( 'id-1' );
		
	}
	
	function toggleSection(id) {
		if(currentID != '') {
			document.getElementById('section-'+currentID).style.display = "none";
			
		}
		document.getElementById('section-'+id).style.display = "block";
		currentID = id;
	}